import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApiUrlForId, UsersEndpoints, UsersRewards } from '../../api/endpoints';
import {
  AddBonusPointsApiModel,
  CampaignUserApiModel,
  UserApiModel,
  UserMetricsAPIModel,
} from '../../api/models/users';
import { httpClient } from '../../services/httpClient/httpClient';
import { UserDetailsDataDTO } from '../storeModels';
import { BonusPointsOptions, GetDataById } from '../../api/models/common';
import { ActivityApiModel } from '../../api/models/rewards';
import { updateTable } from './heroesSlice';
import { fillEmptyUserFields } from '../../services/utilities';
import { CampaignsApiModel } from '../../api/models/campaigns';

const initialState: UserDetailsDataDTO = {
  isLoadingUserDetails: false,
  isLoadingCampaignUser: false,
  userDetails: null,
  campaignUser: null,
  isUserDetailsError: false,
  activities: {
    items: [],
    isLoading: false,
    isError: false,
    lastUpdated: new Date().toISOString(),
  },
  lastUpdated: new Date().toISOString(),
  rewardApproved: false,
  isLoadingRewardApproval: false,
  isErrorRewardApproval: false,
  isLoadingAddBonusPoints: false,
  isErrorAddBonusPoints: false,
  AwardedBonusPoints: false,
  latestActiveCampaign: null,
};

export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const result = await httpClient.get<null, UserApiModel>({
        url: getApiUrlForId(UsersEndpoints.GetUser, id),
        requiresToken: true,
      });
      return {
        ...result,
        displayName:
        result.displayName || `${result.firstName} ${result.lastName}` || result.videos
          ? 'Anonymous Creator'
          : 'Anonymous Visitor',
      firstName: result.firstName || result.lastName ? result.firstName : 'Anonymous',
      lastName: result.firstName || result.lastName ? result.lastName : result.videos ? 'Creator' : 'Visitor',
      };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchUserDetailsNoLoading = createAsyncThunk(
  'userDetails/fetchUserDetailsNoLoading',
  async (id: string) => {
    return await httpClient.get<null, UserApiModel>({
      url: getApiUrlForId(UsersEndpoints.GetUser, id),
      requiresToken: true,
    });
  },
);
export const updateUserById = createAsyncThunk(
  'userDetails/updateUserById',
  async (options: { update: Partial<UserApiModel>; id: string }) => {
    try {
      return await httpClient.put<Partial<UserApiModel>, UserApiModel>({
        url: getApiUrlForId(UsersEndpoints.updateUserById, options.id),
        requiresToken: true,
        payload: options.update,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const fetchUserMetrics = createAsyncThunk(
  'userDetails/fetchUserMetrics',
  async (options: GetDataById) => {
    return await httpClient.get<GetDataById, UserMetricsAPIModel>({
      url: getApiUrlForId(UsersEndpoints.GetUserMetrics, options.id),
      requiresToken: true,
    });
  },
);

export const fetchUserActivities = createAsyncThunk(
  'userDetails/fetchUserActivities',
  async (options: GetDataById) => {
    return await httpClient.get<GetDataById, ActivityApiModel[]>({
      url: getApiUrlForId(UsersRewards.GetActivities, options.id),
      requiresToken: true,
    });
  },
);

export const getCampaignUser = createAsyncThunk(
  'userDetails/getCampaignUser',
  async ({ userId, campaignId }: { userId: string; campaignId: string }, { rejectWithValue }) => {
    try {
      const result = await httpClient.get<null, CampaignUserApiModel>({
        url: UsersEndpoints.GetCampaignUser.replace(/:userId/, userId).replace(
          /:campaignId/,
          campaignId,
        ),
        requiresToken: true,
      });
      return {
        ...result,
        userName: result.userName.trim()
          ? result.userName
          : result.videos
          ? 'Anonymous Creator'
          : 'Anonymous Visitor',
      };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getLatestActiveCampaign = createAsyncThunk(
  'userDetails/getLatestActiveCampaign',
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<null, CampaignsApiModel>({
        url: UsersEndpoints.GetLatestActiveCampaign.replace(/:id/, userId),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const approveReward = createAsyncThunk(
  'userDetails/approveReward',
  async (options: GetDataById, { dispatch }) => {
    try {
      const response = await httpClient.post<{ campaignId?: string; userId: string }, undefined>({
        url: UsersRewards.ApproveReward,
        requiresToken: true,
        payload: { campaignId: options.campaignId, userId: options.id },
      });
      dispatch(updateTable());
      return response;
    } catch (err) {
      throw new Error();
    }
  },
);

export interface ApproveBatchProps {
  campaignId: string;
  userIds: string[];
}

export const approveRewardBatch = createAsyncThunk(
  'userDetails/approveRewardBatch',
  async (options: ApproveBatchProps, { dispatch }) => {
    try {
      return await httpClient.post<ApproveBatchProps, undefined>({
        url: UsersRewards.ApproveRewardBatch,
        requiresToken: true,
        payload: options,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const addBonusPoints = createAsyncThunk(
  'userDetails/addBonusPoints',
  async (options: BonusPointsOptions) => {
    try {
      return await httpClient.put<
        { campaignId?: string; points: number },
        Promise<AddBonusPointsApiModel>
      >({
        url: getApiUrlForId(UsersRewards.AddBonusPoints, options.id),
        requiresToken: true,
        payload: { campaignId: options.campaignId, points: options.points },
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const UserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    updateUserDetails: (state) => {
      state.lastUpdated = new Date().toISOString();
    },
    resetRewardApproval: (state) => {
      state.rewardApproved = false;
      state.isLoadingRewardApproval = false;
      state.isErrorRewardApproval = false;
    },
    resetAwardedBonusPoints: (state) => {
      state.isLoadingAddBonusPoints = false;
      state.isErrorAddBonusPoints = false;
      state.AwardedBonusPoints = false;
    },
    resetCampaignUser: (state) => {
      state.campaignUser = null;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(fetchUserDetails.rejected, (state) => {
      state.isUserDetailsError = true;
    });
    reducersBuilder.addCase(fetchUserDetails.pending, (state) => {
      state.isUserDetailsError = false;
      state.isLoadingUserDetails = true;
    });
    reducersBuilder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      state.isLoadingUserDetails = false;
      state.isUserDetailsError = false;
      state.userDetails = fillEmptyUserFields(payload);
    });
    reducersBuilder.addCase(fetchUserMetrics.rejected, (state) => {
      state.userMetrics = { isErrorUserMetrics: true, isLoadingUserMetrics: false, metrics: null };
    });
    reducersBuilder.addCase(fetchUserMetrics.pending, (state) => {
      state.userMetrics = { isErrorUserMetrics: false, isLoadingUserMetrics: true, metrics: null };
    });
    reducersBuilder.addCase(fetchUserMetrics.fulfilled, (state, { payload }) => {
      state.userMetrics = {
        isErrorUserMetrics: false,
        isLoadingUserMetrics: false,
        metrics: payload,
      };
    });
    reducersBuilder.addCase(fetchUserActivities.rejected, (state) => {
      state.activities.isError = true;
      state.activities.isLoading = false;
    });
    reducersBuilder.addCase(fetchUserActivities.pending, (state) => {
      state.activities.isError = false;
      state.activities.isLoading = true;
    });
    reducersBuilder.addCase(fetchUserActivities.fulfilled, (state, { payload }) => {
      state.activities.isLoading = false;
      state.activities.isError = false;
      state.activities.items = payload;
      state.activities.lastUpdated = new Date().toISOString();
    });
    reducersBuilder.addCase(approveReward.pending, (state) => {
      state.isErrorRewardApproval = false;
      state.isLoadingRewardApproval = true;
    });
    reducersBuilder.addCase(approveReward.rejected, (state) => {
      state.isErrorRewardApproval = true;
      state.isLoadingRewardApproval = false;
    });
    reducersBuilder.addCase(approveReward.fulfilled, (state) => {
      state.isErrorRewardApproval = false;
      state.isLoadingRewardApproval = false;
      state.activities.lastUpdated = new Date().toISOString();
      state.rewardApproved = true;
    });

    reducersBuilder.addCase(approveRewardBatch.pending, (state) => {
      state.isErrorRewardApproval = false;
      state.isLoadingRewardApproval = true;
    });
    reducersBuilder.addCase(approveRewardBatch.rejected, (state) => {
      state.isErrorRewardApproval = true;
      state.isLoadingRewardApproval = false;
    });
    reducersBuilder.addCase(approveRewardBatch.fulfilled, (state) => {
      state.isErrorRewardApproval = false;
      state.isLoadingRewardApproval = false;
    });
    reducersBuilder.addCase(addBonusPoints.pending, (state) => {
      state.isLoadingAddBonusPoints = true;
      state.isErrorAddBonusPoints = false;
    });
    reducersBuilder.addCase(addBonusPoints.fulfilled, (state, { payload }) => {
      if (payload.success === true) {
        state.AwardedBonusPoints = true;
      }
      state.isLoadingAddBonusPoints = false;
      state.isErrorAddBonusPoints = false;
    });
    reducersBuilder.addCase(addBonusPoints.rejected, (state) => {
      state.isLoadingAddBonusPoints = false;
      state.isErrorAddBonusPoints = true;
    });

    // update user info without loading
    reducersBuilder.addCase(fetchUserDetailsNoLoading.fulfilled, (state, { payload }) => {
      state.userDetails = payload;
    });
    reducersBuilder.addCase(updateUserById.fulfilled, (state, { payload }) => {
      state.userDetails = { ...state.userDetails, ...payload };
    });
    reducersBuilder.addCase(getCampaignUser.rejected, (state) => {
      state.isUserDetailsError = true;
      state.isLoadingCampaignUser = false;
    });
    reducersBuilder.addCase(getCampaignUser.pending, (state) => {
      state.isUserDetailsError = false;
      state.isLoadingCampaignUser = true;
    });
    reducersBuilder.addCase(getCampaignUser.fulfilled, (state, { payload }) => {
      state.isLoadingCampaignUser = false;
      state.isUserDetailsError = false;
      state.campaignUser = payload;
    });

    reducersBuilder.addCase(getLatestActiveCampaign.rejected, (state) => {
      state.isUserDetailsError = true;
      state.latestActiveCampaign = null;
    });

    reducersBuilder.addCase(getLatestActiveCampaign.fulfilled, (state, { payload }) => {
      state.latestActiveCampaign = payload;
    });
  },
});

export const {
  updateUserDetails,
  resetCampaignUser,
  resetAwardedBonusPoints,
} = UserDetailsSlice.actions;
export default UserDetailsSlice.reducer;
