import { DiscountType } from '../../pages/QuickStart/Rewards/RewardTypeCustomizationSection/RewardTypeCustomizationSection.helper';

import { Card } from '../../pages/webappSettings/WebAppCards/WebAppCards.helper';
import { Activity, CustomerActivitiesType } from './campaigns';
import { ClientTypes } from './common';

export interface VenueAPIModel {
  id: string;
  name: string;
  organizationName: string;
  city: string;
  logo: string;
  timeZone: string;
  teamName?: string;
  subdomain: string;
  orgId: string;
  refId: string;
  clientType: ClientTypes;
  createdAt: string;
  websiteUrl?: string;
  businessAddress?: string;
  distributorName?: string;
  archivedBy?: string;
  deactivatedAt?: string;
  setupCompleted?: boolean;
  tutorialCompleted?: boolean;
  planId?: string;
  industry?: string | null;
}

export interface VenueApiModelWithStats extends VenueAPIModel {
  managers: number;
  users: number;
  videos: number;
  videoViews: number;
  convertedReferrals: number;
  orgName: string;
  ctaClicks: number;
  distributorId?: string;
  distributorName?: string;
  affiliateId?: string;
  affiliateName?: string;
  pricingPlanName?: string;
  lastCampaignInvitationAddedAt?: string;
  createdByName?: string;
  createdBy?: string;
  soldByName?: string;
  socialverses?: number;
  numActiveCampaigns?: number;
  numPublishedVideos?: number;
  numInvitesSent?: number;
  numGbpVideos?: number;
  lastSuccessfulBillingDate?: string;
  lastFailedBillingDate?: string;
  ownerName: string;
  billable: boolean;
  memberLastLoginAt?: string;
}

export interface VenueAttributesAPIModel {
  [key: string]: string;
}

export interface GetVenueProperties {
  id: string;
}

export enum ModalUploadImageType {
  Logo = 'logo',
  VenueAttribute = 'venueAttribute',
}

export enum VenueAttributeName {
  HomeHero = 'webapp.promo-image',
  RewardsPopUp = 'webapp.rewards-popup',
  VideoProcessingScreen = 'webapp.video-processing-screen',
  DesktopHero = 'webapp.promo-image-desktop',
  DesktopImage2 = 'webapp.desktop-phone-image',
  HowToCreateVideo = 'webapp.ftue-video',
  SalesCodeStructure = 'webapp.sales-code-structure',
  ShareText = 'webapp.share-text',
  AverageInfluencerFollowers = 'analytics.default-followers',
  AssetValueMultiplier = 'analytics.asset-value-multiplier',
  ImpressionViewRate = 'analytics.impressions-multiplier',
  CMPRate = 'analytics.cpm',
  SalesValue = 'analytics.sales-value',
}

export interface ImageAttribute {
  name: VenueAttributeName;
  value: string;
}

export type CreateOrUpdateVenueAttributesRequest = ImageAttribute[];

export interface CreateOrUpdateVenueAttributesResponseItem {
  id: string;
  objectId: string;
  name: VenueAttributeName;
  value: string;
}

export type CreateOrUpdateVenueAttributesResponse = CreateOrUpdateVenueAttributesResponseItem[];

export interface demoMember {
  business_description: string;
  parent_dmo_account_id: string;
}
export interface UnverifiedDMO {
  business_description: string;
}

export interface PatchVenueOptions {
  id: string;
  update: {
    logo?: string;
    organizationName?: string;
    name?: string;
    teamName?: string;
    websiteUrl?: string;
    businessAddress?: string;
    setupCompleted?: boolean;
    tutorialCompleted?: boolean;
    dmo_member?: demoMember;
  };
}
export interface FinalizeAccountOptions {
  id: string;
  websiteUrl?: string;
  businessAddress?: string;
  dmo_member?: demoMember | null;
  unverified_dmo_account?: UnverifiedDMO | null;
  properties?: Object;
}

export interface PatchVenuePropertiesOptions {
  id: string;
  update: {
    name: string;
    value: Object;
  };
  alert?: boolean;
}

export interface PatchVenue {
  logo?: string;
  organizationName?: string;
  name?: string;
  businessAddress?: string;
}

export interface PatchVenueProperties {
  name: string;
  value: Object;
}

export interface FinalizeProperties {
  [key: string]: Object | boolean | string;
}

export interface GetServiceAgreementOptions {
  id: string;
}
export interface PostServiceAgreementOptions {
  id: string;
  file: FormData;
}

export type GetServiceAgreementResponse = string;

export interface VenueCard {
  'tip-media-type': 'video' | 'image';
  'tip-media-url': string;
  'tip-text': string;
}

export enum DefaultVideoDetailsEditable {
  Full = 'full',
  Limited = 'limited',
}

export interface WebAppSettings {
  metaTitle: boolean;
  videoText: boolean;
  ctaBtnText: boolean;
  ctaBtnLinkUrl: boolean;
  videoMessage: boolean;
}

export interface SharingSettings {
  Message: boolean;
  Email: boolean;
  Twitter: boolean;
  Instagram: boolean;
  Facebook: boolean;
  Link: boolean;
  LinkedIn: boolean;
  Download: boolean;
}

export interface FirstTimeOnboardingConfigOption {
  modal: boolean;
}

export interface FirstTimeOnboardingConfig {
  incentives: {
    modal: boolean; // one modal for all screens
    points: boolean;
    sms: boolean;
    fulfillment: boolean;
  };
  invites: {
    modal: boolean; // one modal for all screens
    email: boolean;
    sms: boolean;
    qr: boolean;
  };
  webapp_modal: FirstTimeOnboardingConfigOption;
  webapp: {
    modal: boolean; // one modal for all screens
    branding_style: boolean;
    webapp_signup_config: boolean;
    webapp_sharing: boolean;
    tracking_pixel: boolean;
    webapp_download: boolean;
  };
  sidebar: {
    video_app: boolean;
    campaigns: boolean;
  };
}

export type SharingOrder = {
  [key in keyof SharingSettings]?: number;
};

export type FilterLogoPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export enum LogoPresentation {
  Default = 'default',
  BrandColor = 'brand-color',
  SplashImage = 'splash-image',
}

export enum LogoPresentationLayout {
  Straight = 'straight',
  Curved = 'curved',
  Fade = 'fade',
}

export interface WebAppSignUpConfig {
  phoneRequired: boolean;
  nameRequired: boolean;
  emailRequired: boolean;
  logoPresentation?: LogoPresentation;
  logoPresentationLayout?: LogoPresentationLayout;
  showCompanyName?: boolean;
}

export enum QrCodeType {
  Branded = 'BRANDED',
  Default = 'DEFAULT',
}

export interface QrCodeConfig {
  logo: string;
  brandColor: string;
  qrCodeType?: QrCodeType;
}

export enum VideoAppTheme {
  Dark = 'dark',
  Light = 'light',
}

export enum QsLaunchPadStepStatus {
  Completed = 'Completed',
  Skipped = 'Skipped',
}

export interface QsLaunchPadStepTracking {
  stepName: string;
  status: QsLaunchPadStepStatus;
}

// ! Do not use camelCase in property name
// property name should be in snake_case or kebab-case
export interface VenueProperties {
  'webapp.topgolf.config': {
    imageNewUrl: string;
    shotTimeSlotIncrement: string;
    title: string;
    subtitle: string;
    buttonText: string;
    buttonUrl: string;
    filters: {
      filterImageUrl: string;
      isFilterActive: boolean;
      filterId: string;
    };
    activeAd: boolean;
  };
  'rewards-calculator-params': {
    rewardValue?: number;
    rewardBudget?: number;
    numberOfHeroes?: number;

    numberOfSales?: number;
    budget?: number;
    videosCreated?: number;
    calendarEngagement?: number;
  };
  'webapp.venue': {
    slug: string;
    'display-name': string;
    brandname: string;
    id: string;
  };
  'widget.config': {
    'auto-publish': boolean;
  };
  'webapp.config': {
    'logo-img-url': string;
    'primary-color': string;
    'primary-light-color': string;
    'secondary-color': string;
    'text-color': string;
    'landing-card-url': string;
    'landing-card-text': string;
    'rewards-coming-soon-image': string;
    'filter-logo': string;
    'filter-url': string;
    'filter-logo-position': FilterLogoPosition;
    'filter-logo-enabled': boolean;
    'filter-overlay-enabled': boolean;
    'filter-overlay-url': string;
    'filter-primary-color': string;
    'filter-secondary-color': string;
    theme: VideoAppTheme;
    isThemeLayoutEnabled: boolean;
  };
  'webapp.sharing': {
    'backlink-cta-text': 'Donate' | 'Product Page';
    'meta-og-image': string;
    'meta-og-title': string;
    'meta-og-description': string;
    'meta-twitter-img': string;
    'meta-twitter-title': string;
    'meta-twitter-description': string;
    'meta-twitter-site-id': string;
    sharingSettings: SharingSettings;
    sharingSMSNotInContacts: boolean;
    sharingSMSSequence: boolean;
    sharingSMSSequenceAmount: number;
    sharingOrder: SharingOrder[];
  };
  'webapp.loading-cards': VenueCard[] | VenueCard;
  'webapp.tips': VenueCard[] | VenueCard;
  'webapp.welcome-video': string;
  'webapp.welcome-text': string[];
  'webapp.cards-welcome': Card;
  'webapp.cards-tip': Card;
  'webapp.cards-edu': Card; //MYF-setup-default-values-on-creation
  'webapp.launch-screen': {
    cover: 'white' | 'black' | 'branding';
    reveal: 'branding' | 'splash';
    type: 'logo' | 'name';
  };
  'webapp.default-story-details': {
    metaTitle: string;
    metaDescription: string;
    videoText: string;
    ctaBtnText: string;
    ctaBtnLinkUrl: string;
    videoMessage: string;
    editable: DefaultVideoDetailsEditable;
    webAppSettings: WebAppSettings;
  };
  'webapp.incentive.config': {
    title: string;
    action: string;
    imageUrl: string;
    points: number;
    activities: Activity[];
    fistTime?: string;
    name: string;
    rewardTitle: string;
    rewardDescription: string;
    'most-important-user-activity'?: CustomerActivitiesType;
    discountType?: DiscountType;
    isNullReward?: boolean;
  };
  'webapp.firstTimeOnboardingFinished.config': FirstTimeOnboardingConfig;
  'webapp.config.keywords': KeywordItem[];
  'webapp.signUp.config': WebAppSignUpConfig;
  'dashboard.QRCode.config': QrCodeConfig;
  'webapp.sms.config': {
    template: string;
  };
  'uwm.launch.modal': {
    isFirstLoginFlowFinished: boolean;
    isGbpSyncFinished: boolean;
    isWelcomeVideoSetupFinished: boolean;
  };
  'webapp.uwm.intro-video': {
    videoUrl: string | null;
    thumbnailUrl: string | null;
    duration: number | null;
  };
  'webapp.email-template': {
    title: string;
    description: string;
    imageUrl: string;
    buttonTitle: string;
    bulletPointFirst: string;
    bulletPointSecond: string;
    mailSystem: string;
  };
  agreement_attributes: {
    company_address: string;
    company_name: string;
    company_website: string;
    customer_email: string;
    customer_name: string;
    customer_phone: string;
    email_system: string;
    heard_about: string;
    pricing_plan: string;
    reward_system: string;
    sms_system: string;
    webapp_subdomain: string;
  };
  'dashboard.config': {
    logoUrl: string;
    dashboardLogo: string;
  };
  legal_name: string;
  'company-address': {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  'quickstart.config': {
    currentRoute: string;
    isQuickStartInProgress: boolean;
    visitedRoutes: [];
  };
  'dashboard.wasCongratsModalSeen': boolean;
  'dashboard.quickstart-launchpad': {
    started: boolean;
    finished: boolean;
    activeIndex: number | undefined;
    isLaunchpadVisible: boolean;
    isQSMarkerVisible: boolean;
    stepsTracking: QsLaunchPadStepTracking[];
    currentTopicStep: number;
  };
  'dashboard.invite-stats-viewed': string[];
}

export interface KeywordItem {
  keyword: string;
  icon?: string;
}
