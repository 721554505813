import { FC } from 'react';
import { PricingPlanModel } from '../../../../api/models/pricingPlans';
import { getShortPlanPeriod, getThousandsSeparatedVal } from '../../../../services/utilities';
import { Box, Typography, useTheme } from '@mui/material';
import { BillingStrings } from '../../../../common/localization/en';
import { useStyles } from './PricingPlanPreview.style';
import { SocialVenuLogo } from '../../../../common/assets/newDesign/Sidebar/SocialVenuLogo';

interface PricingPlanPreviewProps {
  plan: PricingPlanModel;
  referralPlan: PricingPlanModel | null;
  isTrubluPlan: boolean;
  isEnterPrisePlan: boolean;
  isQuickSignup?: boolean;
}

export const MobilePlanPreview: FC<PricingPlanPreviewProps> = ({
  plan,
  referralPlan,
  isTrubluPlan,
  isEnterPrisePlan,
  isQuickSignup = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const REFERRAL_PRICE_COLOR = '#3F9700';

  // const setupFeeOffset = useMemo(() => {
  //   return getShortPlanPeriod(plan) === BillingStrings.PerYear ? '44px' : '38px';
  // }, [plan]);

  const getSubtitleText = () => {
    if (plan.contractTermMonths) {
      const years = plan.contractTermMonths / 12;
      const isOverOneYear = years > 1;
      return {
        up: isOverOneYear
          ? `${plan.subscriptionFee}/mo for ${plan.contractTermMonths} months`
          : 'Billed Monthly',
        down: `(${years} year contract)`,
      };
    } else {
      return {
        up: 'Billed Monthly',
        down: `(Month to Month Contract)`,
      };
    }
  };

  return (
    <div
      style={{
        padding: '60px 30px',
        background: '#1E0035',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <SocialVenuLogo />
      </div>

      <Box
        py="30px"
        px="15px"
        borderRadius="15px"
        bgcolor={theme.palette.common.white}
        display={isEnterPrisePlan ? 'none' : 'flex'}
        flexDirection="column"
        width="100%"
      >
        <Typography
          fontFamily={'Poppins'}
          color={theme.palette.common.deepDark}
          fontSize="28px"
          fontWeight="500"
          // mb="26px"
          textAlign="center"
        >
          {plan?.details?.displayName || BillingStrings.PreferredPricing}
        </Typography>

        {isQuickSignup && (
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent="center"
            alignItems="center"
            margin="40px 0px"
          >
            <Typography
              fontFamily={'Poppins'}
              fontWeight={700}
              fontSize={'88px'}
              lineHeight={'60px'}
              color="#1A1538"
              marginBottom={'20px'}
            >
              {plan.upfrontFee || plan.subscriptionFee}
            </Typography>
            <div>
              <Typography
                fontFamily={'Poppins'}
                fontSize={'20px'}
                color="#808080"
                fontWeight={'500'}
                lineHeight={'28px'}
                textAlign={'center'}
              >
                {getSubtitleText().up}
              </Typography>
              <Typography
                fontFamily={'Poppins'}
                fontSize={'20px'}
                color="#808080"
                fontWeight={'500'}
                lineHeight={'28px'}
                textAlign={'center'}
              >
                {getSubtitleText().down}
              </Typography>
            </div>
          </Box>
        )}
        {plan?.details?.promoText && (
          <div
            style={{
              borderRadius: '16px',
              background: '#EFEEFC',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              height: '30px',
            }}
          >
            <Typography
              fontFamily={'Poppins'}
              color={theme.palette.primary.main}
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              mb="26px"
              textAlign="center"
            >
              {plan?.details?.promoText}
            </Typography>
          </div>
        )}

        <Box bgcolor={theme.palette.common.pageBackground} borderRadius="12px" py="14px" px="20px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
            pr={0}
          >
            <Box>
              <Typography
                fontFamily={'Poppins'}
                color={theme.palette.common.deepDark}
                fontSize="20px"
                fontWeight="500"
                width="100%"
              >
                {BillingStrings.SetupFeeLongText}
              </Typography>
              <Typography fontFamily={'Poppins'} className={classes.planDescription}>
                {BillingStrings.NewOntimeFee}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontFamily={'Poppins'}
                fontSize="28px"
                fontWeight="700"
                textAlign={'right'}
                sx={{
                  textDecorationLine: referralPlan ? 'line-through' : undefined,
                  color: '#1A1538',
                }}
              >
                {plan.setupFee === '$0' ? 'Waived' : getThousandsSeparatedVal(plan.setupFee)}
              </Typography>

              <Typography
                fontFamily={'Poppins'}
                fontSize="20px"
                fontWeight="500"
                textAlign={'right'}
                color="#808080"
              >
                {plan.customerSupportTier}
              </Typography>
            </Box>
          </Box>
          {referralPlan && (
            <Box display="flex" alignItems="center" justifyContent="space-between" pr={0}>
              <Typography
                fontFamily={'Poppins'}
                color={REFERRAL_PRICE_COLOR}
                fontWeight="500"
                fontSize="20px"
                mr="5px"
              >
                {BillingStrings.AfterPromoCode}
              </Typography>
              <Typography
                fontFamily={'Poppins'}
                color={REFERRAL_PRICE_COLOR}
                fontWeight="500"
                fontSize="32px"
              >
                {getThousandsSeparatedVal(referralPlan.setupFee)}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              {plan.customerSupportTier?.toLowerCase() === 'platinum' && (
                <Typography
                  fontFamily={'Poppins'}
                  fontSize={'17px'}
                  color="#808080"
                  fontWeight={500}
                  lineHeight={'17px'}
                  margin="10px 0px"
                >
                  {BillingStrings.checkItem1}
                </Typography>
              )}

              <Typography
                fontFamily={'Poppins'}
                fontSize={'17px'}
                color="#808080"
                fontWeight={500}
                lineHeight={'17px'}
                margin={plan.customerSupportTier?.toLowerCase() !== 'platinum' ? '10px 0px' : '0px'}
              >
                {BillingStrings.checkItem2}
              </Typography>
            </Box>
          </Box>
          {referralPlan && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="20px" mr="5px">
                {BillingStrings.AfterPromoCode}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="32px">
                  {getThousandsSeparatedVal(referralPlan.subscriptionFee) || '$0'}
                </Typography>
                <Typography component="span" fontSize="20px" color={theme.palette.common.gray}>
                  {getShortPlanPeriod(referralPlan)}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
