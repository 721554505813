import { PricingPlanModel } from '../api/models/pricingPlans';
import { useAppDispatch, useTypedSelector } from '../store';
import { getPricingPlansByDistributorIds } from '../store/slices/pricingPlansSlice';
import { useDistributors } from './useDistributors';

interface FetchPricingPlansConfig {
  forceDistributorsRefetch?: boolean;
}

export const usePricingPlans = () => {
  const dispatch = useAppDispatch();

  const { isDistributorsLoading } = useDistributors();

  const { isLoading: isPricingPlansLoading, pricingPlans, categories } = useTypedSelector(
    (store) => store.pricingPlans,
  );

  const fetchPricingPlans = async (config: FetchPricingPlansConfig = {}) => {
    // let _distributors = [...distributors];
    // if (!_distributors || _distributors.length === 0 || config.forceDistributorsRefetch) {
    //   _distributors = await fetchDistributors();
    // }

    const categoriesIds = categories.map(({ id }) => id);
    const { payload } = await dispatch(
      getPricingPlansByDistributorIds({ distributorIds: categoriesIds }),
    );
    return payload as PricingPlanModel[];
  };

  /**
   * This method does not fetch plans from backend
   */
  const getPlanById = (planId: PricingPlanModel['id']) => {
    return pricingPlans.find(({ id }) => id === planId);
  };

  const isLoading = isDistributorsLoading || isPricingPlansLoading;

  return {
    pricingPlans,
    isPricingPlansLoading: isLoading,
    fetchPricingPlans,
    getPlanById,
  };
};
